import { graphql, useStaticQuery } from "gatsby";

const Query = () => {
  return useStaticQuery(graphql`
    query {
      allContentfulMegamenu {
        nodes {
          node_locale
          chi_siamo {
            value
            key
          }
          cosaFacciamo {
            value
            key
          }
          contattaci {
            value
            key
          }
          caseStudy {
            name
            path
            value
          }
          footerMenu {
            value
            key
          }
          caseHistory {
            customerName
            customerLink
            customerDescription {
              customerDescription
            }
            customerImage {
              gatsbyImageData(width: 300)
              file {
                url
              }
            }
          }
          comeInnoviamo {
            value
            key
          }
        }
      }
    }
  `);
};

export default Query;
