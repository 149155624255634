import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import CaseHistoryItem from "./CaseHistoryItem";
import "./Megamenu.scss";
import hLogoWhite from "../../assets/images/hevelop/hevelop-logo.svg";
import closeIcon from "./images/close-icon.svg";
import LanguageSwitcher from "../LanguageSwitcher/LanguageSwitcher";
import Query from "./query";
import { filterDataByLanguage } from "../../Utils/Language";
import { Link } from "gatsby";
import PropTypes from "prop-types";

const Megamenu = (props) => {
  const data = Query();
  const menuItemsQuery = filterDataByLanguage(
    props.lang,
    data.allContentfulMegamenu
  );

  const getMenuItems = (type) => {
    if (menuItemsQuery[type] !== undefined && menuItemsQuery[type] !== null) {
      return menuItemsQuery[type].map((menuItem, i) => {
        let menuItemClasses = "menu-item disabled";

        if (menuItem.key.includes("mobile")) {
          menuItemClasses = "menu-item d-lg-none";
        } else if (menuItem.value.length) {
          menuItemClasses = "menu-item";
        }

        if (menuItem.key.length) {
          if (
            menuItem.value.includes("mailto") ||
            menuItem.value.includes("medium") ||
            menuItem.value.includes("gemini-commerce")
          ) {
            return (
              /*eslint-disable-next-line*/
              <div className={menuItemClasses} key={i}>
                <a
                  href={menuItem.value}
                  target="_blank"
                  key={i}
                  rel="noreferrer"
                >
                  {menuItem.key}
                </a>
              </div>
            );
          }
          return (
            <div className={menuItemClasses} key={i}>
              <Link
                activeClassName="active"
                to={menuItem.value.length ? menuItem.value : "#"}
              >
                {menuItem.key.replace("- mobile", "")}
              </Link>
            </div>
          );
        }

        return null;
      });
    }
  };

  const getCaseHistoryItems = () => {
    if (menuItemsQuery["caseHistory"] !== undefined) {
      return menuItemsQuery["caseHistory"].map((caseHistoryItem, i) => (
        <CaseHistoryItem
          name={caseHistoryItem.customerName}
          description={caseHistoryItem.customerDescription.customerDescription}
          image={caseHistoryItem.customerImage}
          link={caseHistoryItem.customerLink}
          key={i}
        />
      ));
    }
  };

  const toggleMegaMenuHandler = () => {
    document.querySelector("#megamenu").classList.toggle("show");
    document.querySelector("#fake-menu").classList.toggle("active");
    document.querySelector("body").classList.toggle("disable-scroll");
  };

  return (
    <div id="megamenu">
      <Container fluid>
        <Row className="megamenu-top">
          <Col xs="6">
            <Link to="/">
              <div className="logo">
                <img height="24" src={hLogoWhite} alt="Hevelop Logo White" />
              </div>
            </Link>
          </Col>
          <Col xs="6">
            <button className="close-icon" onClick={toggleMegaMenuHandler}>
              <img height="24" src={closeIcon} alt="Close Icon" />
            </button>
          </Col>
        </Row>
        <Row className="megamenu-content">
          <Col lg="4">
            <div className="menu">{getMenuItems("chi_siamo")}</div>

            <div className="menu">{getMenuItems("cosaFacciamo")}</div>
          </Col>
          <Col lg="4">
            <div className="menu d-none d-lg-block">
              <div className="menu-item disabled">
                <Link to="#">{menuItemsQuery.caseStudy.name}</Link>
              </div>
              {getCaseHistoryItems()}
              <Link
                className="show-more"
                to={
                  menuItemsQuery.caseStudy.path.length
                    ? menuItemsQuery.caseStudy.path
                    : "#"
                }
              >
                {menuItemsQuery.caseStudy.value}
              </Link>
            </div>
          </Col>
          <Col lg="4" style={{ position: "relative" }}>
            <div className="menu">{getMenuItems("comeInnoviamo")}</div>
            <div className="menu-footer">
              <div className="menu-items align-items-center">
                <LanguageSwitcher />
                <div className="megamenu-contacts">
                  <div className="megamenu-menu-items">
                    {getMenuItems("footerMenu")}
                  </div>
                  <div className="contact-button">
                    <Link
                      to={
                        menuItemsQuery.contattaci.value.length
                          ? menuItemsQuery.contattaci.value
                          : "#"
                      }
                      className="btn-green"
                    >
                      {menuItemsQuery.contattaci.key}
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

Megamenu.propTypes = {
  lang: PropTypes.string.isRequired,
};

export default Megamenu;
