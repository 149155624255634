export function filterDataByLanguage(lang, queryData) {
  let queryResult;

  //filter language
  for (let i = 0; i < queryData.nodes.length; i++) {
    if (lang === queryData.nodes[i].node_locale) {
      queryResult = queryData.nodes[i];
    }
  }

  return queryResult;
}

export function filterListDataByLanguage(lang, queryData) {
  const queryResult = [];

  //filter language
  for (let i = 0; i < queryData.nodes.length; i++) {
    if (lang === queryData.nodes[i].node_locale) {
      queryResult.push(queryData.nodes[i]);
    }
  }

  return queryResult;
}

export function filterSeoDataByLanguage(page, lang, queryData) {
  let queryResult;

  //filter language
  for (let i = 0; i < queryData.nodes.length; i++) {
    if (
      lang === queryData.nodes[i].node_locale &&
      page === queryData.nodes[i].page
    ) {
      queryResult = queryData.nodes[i];
    }
  }

  return queryResult;
}

export function filterSeoDataByOtherLanguages(page, langToExclude, queryData) {
  const queryResult = {};
  queryResult.nodes = [];

  //filter language
  for (let i = 0; i < queryData.nodes.length; i++) {
    if (
      langToExclude !== queryData.nodes[i].node_locale &&
      page === queryData.nodes[i].page
    ) {
      queryResult.nodes.push(queryData.nodes[i]);
    }
  }

  return queryResult;
}
