import { graphql, useStaticQuery } from "gatsby";

const query = () => {
  return useStaticQuery(graphql`
    query {
      allContentfulPartnership {
        nodes {
          node_locale
          geminiPopupTitle
          geminiPopupDescription {
            geminiPopupDescription
          }
          geminiPopupCtaLabel
          geminiLink
          geminiPopupImage {
            gatsbyImageData
            file {
              url
            }
            title
          }
          geminiPopupBackground {
            gatsbyImageData
            file {
              url
            }
            title
          }
        }
      }
    }
  `);
};

export default query;
