import * as React from "react";

const HamburgerMenu = () => {
  const toggleMegaMenuHandler = () => {
    document.querySelector("#megamenu").classList.toggle("show");
    document.querySelector("#fake-menu").classList.toggle("active");
    document.querySelector("body").classList.toggle("disable-scroll");
  };

  return (
    <div className="container-fluid">
      <button id="fake-menu" onClick={toggleMegaMenuHandler}>
        &nbsp;
      </button>
    </div>
  );
};

export default HamburgerMenu;
