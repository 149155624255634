import React from "react";
import { Col, Row } from "react-bootstrap";
import { Link } from "gatsby";
import "./CaseHistoryItem.scss";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import PropTypes from "prop-types";

const caseContentTitleStyle = {
  fontSize: "2.25rem",
};

const CaseHistoryItem = (props) => {
  return (
    <div className="case-item">
      <Row>
        <Col lg="5">
          <div className="case-image">
            <GatsbyImage
              alt={props.name}
              image={getImage(props.image)}
              imgClassName="img-fluid"
            />
          </div>
        </Col>
        <Col lg="6">
          <div className="case-content">
            <Link activeClassName="active" to={props.link}>
              <h3 style={caseContentTitleStyle}>{props.name}</h3>
            </Link>
            {/*{props.description.slice(0, 100)}...*/}
          </div>
        </Col>
      </Row>
    </div>
  );
};

CaseHistoryItem.propTypes = {
  name: PropTypes.string.isRequired,
  image: PropTypes.object.isRequired,
  link: PropTypes.string.isRequired,
};

export default CaseHistoryItem;
