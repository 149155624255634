import * as React from "react";
import PropTypes from "prop-types";
import { Col, Container, Nav, Row } from "react-bootstrap";
import "./Footer.scss";
import hLogoWhiteXl from "../../assets/images/hevelop/hevelop-logo-white.svg";
import query from "./query";
import { useEffect } from "react";
import { filterDataByLanguage } from "../../Utils/Language";

const Footer = (props) => {
  const data = query();

  const footerData = filterDataByLanguage(props.lang, data.allContentfulFooter);

  useEffect(() => {
    // Add current year to footer copyright
    const copyrightEl = document.querySelector(".copyright");
    const copyrightText = copyrightEl.textContent;
    const currentYear = new Date().getFullYear();
    copyrightEl.textContent = copyrightText.replace("{year}", currentYear);
  });

  return (
    <footer className={`page-footer ${props.className}`}>
      <Container fluid>
        <Row>
          <Col>
            <Nav className="flex-lg-row justify-content-between align-items-start flex-column ">
              <Nav.Item className="footer-text hevelop-logo">
                <Nav.Link href="/">
                  <img src={hLogoWhiteXl} alt="Hevelop logo" className="logo" />
                </Nav.Link>
              </Nav.Item>
              <Nav.Item className="footer-text">
                <div className="title-first-row">{footerData.addressCity}</div>
                <div className="address" style={{ whiteSpace: "pre-line" }}>
                  {footerData.addressDetails.addressDetails}
                </div>
              </Nav.Item>
              <Nav.Item className="footer-text">
                <div className="title-first-row">{footerData.contacts}</div>
                <div className="subtitle">
                  {footerData.contactsPhone.contactsPhone}
                </div>
                <div className="subtitle">
                  <a href={"mailto:" + footerData.contactsInfoEmailLink}>
                    {footerData.contactsInfoEmailText}
                  </a>
                </div>
              </Nav.Item>
              <Nav.Item className="footer-text">
                <div className="title-first-row">Supporto</div>
                <div className="subtitle">
                  <a href={"mailto:" + footerData.contactsSupportEmailLink}>
                    {footerData.contactsSupportEmailText}
                  </a>
                </div>
              </Nav.Item>
              <Nav.Item className="footer-text">
                <div className="linkedin-title">
                  <a href={footerData.companyLink3}>
                    {footerData.companyLinkText3}
                  </a>
                </div>
                <div className="main-title">
                  <a href={footerData.companyLink4}>
                    {footerData.companyLinkText4}
                  </a>
                </div>
                <div className="main-title">
                  <a href={footerData.companyLink5}>
                    {footerData.companyLinkText5}
                  </a>
                </div>
                <div className="main-title">
                  <a
                    href={footerData.companyLink6}
                    target="_blank"
                    rel="noreferrer"
                  >
                    {footerData.companyLink6Label}
                  </a>
                </div>
              </Nav.Item>
              <Nav.Item className="footer-text">
                <div className="title-first-row">
                  <a href={footerData.companyLink1}>
                    {footerData.companyLinkText1}
                  </a>
                </div>
                <div className="main-title">
                  <a
                    href={footerData.companyLink2}
                    target="_blank"
                    rel="noreferrer"
                  >
                    {footerData.companyLinkText2}
                  </a>
                </div>
              </Nav.Item>
              <Nav.Item className="footer-text">
                <p className="copyright">{footerData.companyCopyright}</p>
              </Nav.Item>
            </Nav>
          </Col>
        </Row>
      </Container>
    </footer>
  );
};

Footer.propTypes = {
  lang: PropTypes.string.isRequired,
  className: PropTypes.string,
};

export default Footer;
