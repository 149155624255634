import * as React from "react";
import PropTypes from "prop-types";
import { Col, Container, Nav, Row } from "react-bootstrap";
import "./Footer.scss";
import hLogoWhiteXl from "../../assets/images/hevelop/hevelop-logo-white.svg";
import query from "./query";
import { useEffect } from "react";
import { filterDataByLanguage } from "../../Utils/Language";

const FooterDesktop = (props) => {
  const data = query();
  const footerData = filterDataByLanguage(props.lang, data.allContentfulFooter);

  useEffect(() => {
    // Add current year to footer copyright
    const copyrightEl = document.querySelector(".copyright");
    const copyrightText = copyrightEl.textContent;
    const currentYear = new Date().getFullYear();
    copyrightEl.textContent = copyrightText.replace("{year}", currentYear);
  });

  return (
    <div className={`page-footer`}>
      <Container fluid>
        <Row>
          <Col>
            <Nav className="">
              <Nav.Item className="footer-text">
                <Nav.Link href="/">
                  <img src={hLogoWhiteXl} alt="Hevelop logo" className="logo" />
                </Nav.Link>
              </Nav.Item>
              <Nav.Item className="footer-text">
                <p className="title-first-row">{footerData.addressCity}</p>
                <p className="address" style={{ whiteSpace: "pre-line" }}>
                  {footerData.addressDetails.addressDetails}
                </p>
              </Nav.Item>
              <Nav.Item className="footer-text">
                <p className="title-first-row">
                  <a href={footerData.companyLink1}>
                    {footerData.companyLinkText1}
                  </a>
                </p>
                <p className="main-title">
                  {
                    /*eslint-disable-next-line*/
                    <a
                      href={footerData.companyLink2}
                      target="_blank"
                      rel="noreferrer"
                    >
                      {footerData.companyLinkText2}
                    </a>
                  }
                </p>
              </Nav.Item>
              <Nav.Item className="footer-text">
                <p className="title-first-row">{footerData.contacts}</p>
                <p className="contact-text-first">
                  {footerData.contactsPhone.contactsPhone}
                </p>
                <p className="contact-text-second">
                  <a href={"mailto:" + footerData.contactsInfoEmailLink}>
                    {footerData.contactsInfoEmailText}
                  </a>
                </p>
                <p className="support-title">Supporto</p>
                <p className="">
                  <a href={"mailto:" + footerData.contactsSupportEmailLink}>
                    {footerData.contactsSupportEmailText}
                  </a>
                </p>
              </Nav.Item>
              <Nav.Item className="footer-text last">
                <p className="title-first-row">
                  <a
                    href={footerData.companyLink3}
                    target="_blank"
                    rel="noreferrer"
                  >
                    {footerData.companyLinkText3}
                  </a>
                </p>
                <p className="main-title">
                  <a
                    href={footerData.companyLink4}
                    target="_blank"
                    rel="noreferrer"
                  >
                    {footerData.companyLinkText4}
                  </a>
                </p>
                <p className="main-title">
                  <a
                    href={footerData.companyLink5}
                    target="_blank"
                    rel="noreferrer"
                  >
                    {footerData.companyLinkText5}
                  </a>
                </p>
                <p className="main-title">
                  <a
                    href={footerData.companyLink6}
                    target="_blank"
                    rel="noreferrer"
                  >
                    {footerData.companyLink6Label}
                  </a>
                </p>
              </Nav.Item>
            </Nav>
          </Col>
        </Row>
        <Row className="company-copyright-row">
          <Col>
            <Nav className="">
              <Nav.Item className="copyright-wrapper">
                <p className="copyright">{footerData.companyCopyright}</p>
              </Nav.Item>
            </Nav>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

FooterDesktop.propTypes = {
  className: PropTypes.string,
  lang: PropTypes.string.isRequired,
};

export default FooterDesktop;
