import React, { useEffect, useState } from "react";
import { Row, Col } from "react-bootstrap";
import query from "./query";
import PropTypes from "prop-types";
import { useCookies } from "react-cookie";
import { filterDataByLanguage } from "../../Utils/Language";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import ReactHtmlParser from "react-html-parser";
import closeIcon from "../Megamenu/images/close-icon.svg";
import "./GeminiPopup.scss";

const GeminiPopup = (props) => {
  const data = query();
  const geminiPopupData = filterDataByLanguage(
    props.lang,
    data.allContentfulPartnership
  );

  const [isOpen, setIsOpen] = useState(false);
  const [cookies, setCookie] = useCookies(["gemini-popup"]);

  const close = () => {
    setIsOpen(false);
    setCookie("gemini-popup", true, {
      path: "/",
      maxAge: 604800, // 1 week
    });
  };

  useEffect(() => {
    if (cookies && !cookies["gemini-popup"]) {
      setIsOpen(true);
    }
  });

  return (
    isOpen && (
      <div className="gemini-popup position-fixed top-0 start-0 bottom-0 end-0 col-12 d-flex justify-content-center align-items-center visible">
        <div
          className="gemini-popup-overflow position-absolute top-0 start-0 bottom-0 end-0"
          onClick={close}
        />
        <Row
          className="gemini-popup-modal align-items-center"
          style={{
            backgroundImage: `url(${geminiPopupData.geminiPopupBackground.file.url})`,
          }}
        >
          <Col className="gemini-popup-left col-lg-5 col-12 d-flex align-items-center">
            <div>
              <div className="gemini-popup-title pb-3">
                {ReactHtmlParser(geminiPopupData.geminiPopupTitle)}
              </div>
              <div className="gemini-popup-description pb-3 mb-lg-3">
                {geminiPopupData.geminiPopupDescription.geminiPopupDescription}
              </div>
              <a
                className="gemini-popup-cta"
                href={geminiPopupData.geminiLink}
                target="_blank"
                rel="noreferrer"
              >
                {geminiPopupData.geminiPopupCtaLabel}
              </a>
            </div>
          </Col>
          <Col className="gemini-popup-right col-lg-7 col-12">
            <GatsbyImage
              className="gemini-popup-image"
              image={getImage(geminiPopupData.geminiPopupImage)}
              alt={geminiPopupData.geminiPopupImage.title}
            />
          </Col>
          <button className="gemini-popup-close" onClick={close}>
            <img height="20" src={closeIcon} alt="Close Icon" />
          </button>
        </Row>
      </div>
    )
  );
};

GeminiPopup.propTypes = {
  lang: PropTypes.string.isRequired,
};

export default GeminiPopup;
