import * as React from "react";
import FooterDesktop from "./FooterDesktop";
import FooterMobile from "./FooterMobile";
import { useEffect, useState } from "react";
import PropTypes from "prop-types";

const isBrowser = typeof window !== "undefined";

const Footer = (props) => {
  const [width, setWidth] = useState(0);
  useEffect(() => {
    if (isBrowser) {
      setWidth(window.innerWidth);
    }
  });

  return (
    <>
      {(props.display === undefined || props.display) && width >= 810 && (
        <FooterDesktop lang={props.lang} />
      )}
      {width < 810 && <FooterMobile lang={props.lang} />}
    </>
  );
};

Footer.propTypes = {
  lang: PropTypes.string.isRequired,
  display: PropTypes.bool,
};

export default Footer;
