import * as React from "react";
import { useCookies } from "react-cookie";
import PropTypes from "prop-types";
import { useEffect } from "react";
import { Container, Navbar } from "react-bootstrap";
import "./Header.scss";
import hLogo from "./images/h-logo.svg";
import hLogoXl from "../../assets/images/hevelop/hevelop-logo.svg";
import Megamenu from "../Megamenu/Megamenu";
import Favicon from "../Favicon/Favicon";
import GeminiPopup from "../GeminiPopup/GeminiPopup";

const Header = (props) => {
  let dark = "";
  if (props.isDarkTheme === true) {
    dark = "dark";
  }
  const [cookies, setCookie] = useCookies(["hevelop-language"]);

  let language = "en";
  if (props.lang === "it") {
    language = "it";
  }

  // store language in a cookie
  if (cookies["hevelop-language"] !== language) {
    setCookie("hevelop-language", language, {
      path: "/",
      maxAge: 2592000,
    });
  }

  const toggleLogo = () => {
    const header = document.querySelector("header");
    const logoSmall = document.querySelector(".navbar-brand .logo-small");
    const logoLarge = document.querySelector(".navbar-brand .logo-large");

    if (window.scrollY >= 0) {
      if (window.scrollY === 0) {
        logoLarge.style.opacity = 1;
        logoSmall.style.opacity = 0;
        setTimeout(function () {
          logoLarge.style.display = "flex";
          logoSmall.style.display = "none";
        }, 250);
      } else if (
        header.scrollHeight - Math.abs(header.scrollTop) ===
        header.clientHeight
      ) {
        logoLarge.style.opacity = 0;
        logoSmall.style.opacity = 1;
        setTimeout(function () {
          logoLarge.style.display = "none";
          logoSmall.style.display = "flex";
        }, 250);
      }
    }
  };

  // Trigger toggleLogo after component rendering
  useEffect(() => {
    if (window.screen.width > 810) {
      window.addEventListener("scroll", toggleLogo);
    }
    document.querySelector("body").classList.remove("disable-scroll");
  }, []);

  return (
    <header className="page-header">
      <Favicon />
      <GeminiPopup lang={props.lang} />
      <Navbar>
        <Container fluid>
          <Navbar.Brand href={"/" + language}>
            <span className="logo-small">
              <img src={hLogo} height="24" alt="Hevelop logo small" />
            </span>
            <span className="logo-large">
              <img
                className={dark}
                src={hLogoXl}
                height="24"
                alt="Hevelop logo large"
              />
            </span>
          </Navbar.Brand>
        </Container>
        <Megamenu lang={props.lang} />
      </Navbar>
    </header>
  );
};

Header.propTypes = {
  lang: PropTypes.string.isRequired,
  isDarkTheme: PropTypes.bool,
};

export default Header;
