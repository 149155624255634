import React from "react";
import { Link } from "gatsby";
import "./LanguageSwitcher.scss";
import { useCookies } from "react-cookie";

const LanguageSwitcher = () => {
  const [cookies, setCookie] = useCookies(["hevelop-language"]);

  const isLanguageActive = (lang) => {
    if (cookies["hevelop-language"] !== undefined) {
      if (cookies["hevelop-language"] === lang) return true;
    }
    return false;
  };

  const handleChangeLanguage = (lang) => {
    setCookie("hevelop-language", lang, {
      path: "/",
      maxAge: 2592000,
    });
    return true;
  };

  return (
    <div className="language-switcher text-white">
      <Link
        to="/it/"
        className={isLanguageActive("it") ? "active" : ""}
        onClick={() => handleChangeLanguage("it")}
      >
        IT
      </Link>
      <span> | </span>
      <Link
        to="/en/"
        className={isLanguageActive("en") ? "active" : ""}
        onClick={() => handleChangeLanguage("en")}
      >
        EN
      </Link>
    </div>
  );
};

export default LanguageSwitcher;
