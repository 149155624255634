import { graphql, useStaticQuery } from "gatsby";

const query = () => {
  return useStaticQuery(graphql`
    query {
      allContentfulFooter {
        nodes {
          addressCity
          addressDetails {
            addressDetails
          }
          companyCopyright
          companyLink1
          companyLink2
          companyLink3
          companyLink4
          companyLink5
          companyLink6
          companyLinkText1
          companyLinkText2
          companyLinkText3
          companyLinkText4
          companyLinkText5
          companyLink6Label
          contacts
          contactsInfoEmailLink
          contactsInfoEmailText
          contactsPhone {
            contactsPhone
          }
          contactsSupportEmailLink
          contactsSupportEmailText
          node_locale
        }
      }
    }
  `);
};

export default query;
